import {Component} from "react";
import React from "react";
//import {SocialIcon} from 'react-social-icons';
import './../styles/Footer.css'

class Footer extends Component {

	render() {
		return <footer className="page-footer font-small blue">
			<div className="footer-copyright text-center py-3">
				<img className="footer__logo" src="./images/logo2.png" alt="Logo"></img>
				Universidad Sergio Arboleda
			</div>
		</footer>

	}

}

export default Footer;