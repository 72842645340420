import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Popup from "reactjs-popup";

import Menu from "./components/Menu";
import BurgerIcon from "./components/BurgerIcon";

import GenerateSchedules from "./GenerateSchedules";
import SearchSubjectSchedule from "./SearchSubjectSchedule";
import SchedulesVerificationGuide from "./SchedulesVerificationGuide";

const contentStyle = {
    background: "rgba(255,255,255,0)",
    width: "80%",
    border: "none"
};

class App extends Component {

    render() {
        return (<div className="App">
                <Router>
                    <Popup
                        modal
                        overlayStyle={{background: "rgba(255,255,255,0.98"}}
                        contentStyle={contentStyle}
                        closeOnDocumentClick={false}
                        trigger={open => <BurgerIcon open={open}/>}
                    >
                        {close => <Menu close={close}/>}
                    </Popup>

                    <Routes>
                        <Route exact path="/" element={<GenerateSchedules/>}/>
                        <Route path="/horario-por-asignatura" element={<SearchSubjectSchedule/>}/>
                        <Route path="/guia-verificacion-horarios" element={<SchedulesVerificationGuide/>}/>
                    </Routes>
                </Router>
            </div>
        )
    }

}

export default App;
