import React, {Component} from 'react';
import gql from 'graphql-tag';
import {ApolloConsumer} from "react-apollo";
import {GraphQLEnumType} from "graphql";
import Fab from '@material-ui/core/Fab';
import ForwardIcon from '@material-ui/icons/Forward10';
import {withStyles} from "@material-ui/core";

const PAGE_SIZE = 10;

const styles = theme => ({
	fab: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
});

export default withStyles(styles)(
	class SeeMoreButton extends Component {

		constructor(props) {
			super(props);
			this.state = {offset: 0};
		}

		async loadSchedules(client) {
			this.props.loading();

			const {data} = await client.query({
				query: GET_SCHEDULES,
				variables: {
					careerSeat: this.props.careerSeat,
					subjectsCode: this.props.subjectsCode.sort(),
					blockCells: this.props.blockCells,
					preferredTime: new GraphQLEnumType({name: this.props.preferredTime, values: {FOO: {}}}),
					offset: this.state.offset
				}
			});

			return data;
		}

		onAcceptButtonClick = (client) => {
			this.setState({offset: this.state.offset + PAGE_SIZE},
				() =>
					this.loadSchedules(client)
						.then((data) => {
								this.props.setHasMoreSchedules(data.schedule.next);
								this.onSchedulesFetched(data.schedule.schedules);
							}
						)
						.catch((reason) =>
							console.log('Error fetching schedules pagination. %s', reason)
						)
			);
		};

		onSchedulesFetched = schedules => this.props.setMoreSchedules(schedules);

		render() {
			const {classes} = this.props;
			return (<ApolloConsumer>
					{client =>
						<div className="col text-center mb-3 mt-3">
							<Fab variant="extended" aria-label="Delete" className={classes.fab}
									 onClick={() => this.onAcceptButtonClick(client)}>
								<ForwardIcon className={classes.extendedIcon}/>
								Generar más horarios
							</Fab>
						</div>
					}
				</ApolloConsumer>
			);
		}

	}
)
const GET_SCHEDULES = gql`
			query($careerSeat: String!, $subjectsCode: [Int!]!, $blockCells: [Cell!], $preferredTime: DayTime!, $offset: Int!) {
  			schedule(payload: {careerSeat: $careerSeat, subjectsCode: $subjectsCode, blockCells: $blockCells, preferredTime: $preferredTime}, offset: $offset) {
  				schedules {
  					code
    				listRepresentation
    		 	}
    		 	next
  			} 
			}	
			`;