import React, {Component} from 'react';
import '../styles/UserSubjects.css'

class UserSubjectsForm extends Component {

	onSubmitSubjectRemoved = (subject) => {
		this.props.onSubmit(subject, false);
	};

	displaySubjectsSelected = () => {
		let subjectsSelected = JSON.parse('[' + this.props.subjectsSelected + ']');
		return subjectsSelected.map(subject => {
			return <li className='list-group-item selected' key={subject.code}>
				<button className='delete-subject-btn' onClick={() => this.onSubmitSubjectRemoved(JSON.stringify(subject))}>
					<span role='img' aria-label='x'>❌</span>
				</button>
				{subject.name} - {subject.shor} - 
				<span className='span_credits font-italic'> {subject.credits} CR.</span>
			</li>
		});
	};

	render() {
		return <div className="col-md mb-3">
			<div className="card bg-light">
				<div className="card-header"><h4>Asignaturas Seleccionadas</h4></div>
				<div className="card-body elements-container">
					<ul className="list-group">
						{this.displaySubjectsSelected()}
					</ul>
				</div>
			</div>
			<br></br>
			<div className="alert alert-info">
				<strong>Total créditos:</strong> <span id="total_credits">{this.props.totalCredits}</span>
			</div>
		</div>;
	}

}

export default UserSubjectsForm;