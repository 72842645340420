import React, {Component} from 'react';
import '../styles/Schedule.css'
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import domtoimage from 'dom-to-image';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        color: 'white',
        background: '#004379',
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
});

export default withStyles(styles)(
    class Schedule extends Component {

        shouldComponentUpdate(nextProps) {
            return nextProps.schedules !== this.props.schedules;
        }

        displaySchedules() {
            return this.props.schedules && this.props.schedules.length > 0 ? this.getSchedules() : this.getNoSchedulesMessage();
        }

        getSchedules = () => {
            const {classes} = this.props;
            let scheduleIndex = 1;
            return this.props.schedules.map(schedule => {
                let hour = 6;
                let materiasvirtuales = 1;
                let scheduleId = 'schedule-' + scheduleIndex;
                return <div className="table-responsive schedule-generated" key={"table-" + scheduleIndex}>
                    <h3 className="d-inline"> Horario {scheduleIndex++}</h3>

                    <Button variant="contained" className={classes.button + ' d-inline btn ml-4 mb-3'}
                            onClick={() => this.downloadSchedule(scheduleId)}>
                        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                        Guardar
                    </Button>
                    <div id={scheduleId}>
                        {/*Tabla Materias Virtuales*/
                            schedule.listRepresentation[0].length >= 6 &&
                            <table className="table table-bordered2">
                                <thead>                                                                
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" style={{color: 'red'}}>HÍBRIDO = Semanalmente se deben ejecutar horas en el aula virtual para completar la asignatura</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Materias independientes de horario</th>
                                </tr>                                    
                                {schedule.listRepresentation.map((row, index) => {
                                    return row[6] !== null &&
                                        <tr key={"table-" + scheduleIndex + "-row-" + index}>
                                            <th className='schedule-row' scope="row">{materiasvirtuales++}</th>
                                            <td className="td-schedule-generated"
                                                key={"table-" + scheduleIndex + "-cell-" + index}>
                                                {row[6] ? row[6].split('\n').map((word, key) => {
                                                    return <span
                                                        className={key > 0 ? "td-text-group" : "td-text-subject"}
                                                        key={key}>{word}<br/></span>
                                                }) : ''}
                                            </td>
                                        </tr>
                                })}
                                </tbody>
                            </table>
                        }
                        {/*tabla principal*/}
                        <table className="table table-bordered" id={"table-" + scheduleId}>
                            <thead>
                            <tr>
                                <th scope="col">Hora</th>
                                <th scope="col">Lunes</th>
                                <th scope="col">Martes</th>
                                <th scope="col">Miércoles</th>
                                <th scope="col">Jueves</th>
                                <th scope="col">Viernes</th>
                                <th scope="col">Sábado</th>
                            </tr>
                            </thead>
                            <tbody>
                            {schedule.listRepresentation.map((row, index) => {

                                return <tr key={"table-" + scheduleIndex + "-row-" + index}>
                                    <th className='schedule-row'
                                        scope="row"> {hour > 12 && hour++ - 12 + ' PM'}{hour === 12 && hour++ + ' PM'}{hour < 12 && hour++ + ' AM'} </th>
                                    {row.map((cell, index) => {
                                        if (index < 6) {
                                            return <td className="td-schedule-generated"
                                                       key={"table-" + scheduleIndex + "-cell-" + index}>
                                                {cell ? cell.split('\n').map((word, key) => {
                                                    return <span
                                                        className={key > 0 ? "td-text-group" : "td-text-subject"}
                                                        key={key}>{word}<br/></span>
                                                }) : ''}
                                            </td>
                                        }
                                        return [];
                                    })}
                                </tr>
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>
            });
        };

        downloadSchedule(scheduleId) {
            let DomRect = document.getElementById(scheduleId).getBoundingClientRect();
            let table = document.getElementById("table-" + scheduleId).getBoundingClientRect();
            domtoimage.toJpeg(document.getElementById(scheduleId), {
                quality: 1, bgcolor: '#FFFFFF', width: table.width,
                height: DomRect.height + 50
            })
                .then(function (dataUrl) {
                    var link = document.createElement('a');
                    link.download = 'Mi Horario.jpeg';
                    link.href = dataUrl;
                    link.click();
                });

            this.props.scheduleDownloaded(scheduleId);
        }

        getNoSchedulesMessage() {
            this.props.noSchedules();
            return <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading text-center">¡Lo sentimos! ☹</h4>
                <p className="text-center">
                    No se pudieron combinar las asignaturas seleccionadas para generar los horarios.</p>
                <p className="text-center"> Por favor intente nuevamente, seleccionando menos asignaturas o efectuando
                    la consulta de cada signatura de forma individual.
                </p>
            </div>;
        }

        render() {
            return (
                <div>
                    {this.displaySchedules()}
                </div>
            );
        }
    }
)


