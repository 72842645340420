import React, {Component} from 'react';

class UserCareersForm extends Component {

	constructor(props) {
		super(props);
		this.state = {studentCareer: '', studentCareerCode: 0, careerSeat: 'NaN', studentSemester: 1};
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.onSubmitStudentCareerValue();
	};

	onChangeStudentCareerValue = event => {
		let careerValid = event.target.value !== 'default';
		if (careerValid) {
			let career = JSON.parse(event.target.value);
			this.setState({studentCareer: career.name, studentCareerCode: career.code, careerSeat: career.seat});
		} else {
			this.setState({studentCareerCode: 0, careerSeat: 'NaN'});
		}
	};

	onChangeSemesterValue = event => {
		let semesterValue = event.target.value;
		if (semesterValue > 10) event.target.value = semesterValue.toString()[0] === '1' && semesterValue.toString()[1] === '0' ? 10 : semesterValue[0];
		this.setState({studentSemester: event.target.value});
	};

	onSubmitStudentCareerValue = () => {
		if (this.state.studentCareerCode && this.props.student && this.props.student.careers) {
			this.props.onSubmit(this.state.studentCareer, this.state.studentCareerCode, this.state.studentSemester, this.state.careerSeat);
		} else {
			this.props.errorAlert('Error', 'Selecciona una carrera válida');
		}
	};

	displayStudentCareers() {
		if (this.props.student && this.props.student.careers) {
			return this.props.student.careers.map(career => {
				return <option key={career.code} value={JSON.stringify(career)}>{career.name} - {career.shortCode}</option>;
			});
		}
	}

	render() {
		return <div className="col-md mb-3">
			<div className="card bg-light">
				<div className="card-header"><h4>Información Académica</h4></div>

				<div className="card-body elements-container">
					<form className="form-horizontal mt-4" onSubmit={this.handleSubmit}>
						<div className="form-group">
							<label className="control-label col">Carrera:</label>
							<div className="col">
								<select className="form-control" id="combobox" name="carrera"
												onChange={this.onChangeStudentCareerValue}>
									<option value="default">Seleccione...</option>
									{this.displayStudentCareers()}
								</select>
							</div>
						</div>

						<div className="form-group">
							<label className="control-label col">Semestre:</label>
							<div className="col">
								<input align="center" type="number" className="form-control" name="semestre" id="semester" min="1"
											 max="10" defaultValue="1" onChange={this.onChangeSemesterValue}/>
							</div>
						</div>
						<div className="col text-center mt-3">
							<button type="button" id="submit" className="btn btn-default"
											onClick={this.onSubmitStudentCareerValue}>Aceptar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
			;
	}

}

export default UserCareersForm;