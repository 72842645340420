import React, {Component} from 'react';
import Header from "./components/Header";
import './styles/App.css'
import './styles/SchedulesVerificationGuide.css'
import Footer from "./components/Footer";

class SchedulesVerificationGuide extends Component {

    render() {
        return (<div className="SchedulesVerificationGuide">
                <Header
                    title="Guía de verificación de horarios"
                    periodKey="period"
                />

                <div className="col-12">
                    <p className="page-subtitle">
                        Todos los grupos tienen un consecutivo acompañado de la letra G, R o V, lo cual indica el
                        tipo de impartición:<br/>
                        - G, B ó S: Presencial (alguna o todas las franjas son presenciales)<br/>
                        - R, RB ó RS: Remoto (ninguna franja es presencial)<br/>
                        - V, VS ó VB: Virtual (sin día y hora)<br/>
                        - A(Bogotá): Híbrido, una o más franjas presenciales y una sesión virtual (asincrónica en el aula virtual)<br/><br/>
                        También, dado que algunas asignaturas pueden ser ofertadas por múltiples programas
                        académicos, se puede identificar el grupo ofrecido por su programa, a través de las
                        iniciales en la denominación del grupo. Ejemplo: el grupo <b>MKVII;ÉTICA Y RESPONSABIL.
                        SOCIAL
                        G01</b>, inicia con las letras MK, por lo cual es ofertado por MARKETING Y NEGOCIOS
                        INTERNACIONALES, de acuerdo a la siguiente tabla:
                    </p>
                </div>

                <div className="row">
                    <div className="col">
                        <table className="schedules_verification_table bg">
                            <thead>
                            <tr className="head">
                                <th colSpan={2}>Bogotá</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className="head">
                                <th>Programa</th>
                                <th>Prefijo</th>
                            </tr>
                            <tr>
                                <td>ADMINISTRACIÓN AMBIENTAL</td>
                                <td>AA</td>
                            </tr>
                            <tr>
                                <td>ADMINISTRACION DE EMPRESAS</td>
                                <td>AD</td>
                            </tr>
                            <tr>
                                <td>ADMINISTRACIÓN DE NEGOCIOS</td>
                                <td>AN</td>
                            </tr>
                            <tr>
                                <td>CENTRO DE INNOVACIÓN, EMPRENDIMIENTO Y EMPRESA</td>
                                <td>DIEM</td>
                            </tr>
                            <tr>
                                <td>CIENCIAS DE LA COMPUTACIÓN E INTELIGENCIA ARTIFICIAL</td>
                                <td>CC</td>
                            </tr>
                            <tr>
                                <td>COMERCIO INTERNACIONAL</td>
                                <td>CI</td>
                            </tr>
                            <tr>
                                <td>COMUNICACIÓN SOCIAL Y PERIODISMO</td>
                                <td>CO</td>
                            </tr>
                            <tr>
                                <td>COMUNICACIÓN SOCIAL Y PERIODISMO DIGITAL</td>
                                <td>CD</td>
                            </tr>
                            <tr>
                                <td>CONTADURÍA DIGITAL TECH</td>
                                <td>CT</td>
                            </tr>
                            <tr>
                                <td>CONTADURÍA PÚBLICA</td>
                                <td>CN</td>
                            </tr>
                            <tr>
                                <td>DECANATURA DE ESTUDIANTES</td>
                                <td>DEES</td>
                            </tr>
                            <tr>
                                <td>DEPARTAMENTO DE ECONOMÍA</td>
                                <td>DPEC</td>
                            </tr>
                            <tr>
                                <td>DEPARTAMENTO DE HUMANIDADES</td>
                                <td>DPHU</td>
                            </tr>
                            <tr>
                                <td>DEPARTAMENTO DE MATEMÁTICAS</td>
                                <td>DPMT</td>
                            </tr>
                            <tr>
                                <td>DEPTO. LECTURA Y ESCRITURA ACADÉMICAS</td>
                                <td>DPGR</td>
                            </tr>
                            <tr>
                                <td>DEPTO. ORGANISMOS DEL ESTADO</td>
                                <td>DPDE</td>
                            </tr>
                            <tr>
                                <td>DERECHO</td>
                                <td>DE</td>
                            </tr>
                            <tr>
                                <td>DISEÑO DIGITAL</td>
                                <td>DG</td>
                            </tr>
                            <tr>
                                <td>ECONOMÍA</td>
                                <td>EC</td>
                            </tr>
                            <tr>
                                <td>FILOSOFÍA Y HUMANIDADES</td>
                                <td>FO</td>
                            </tr>
                            <tr>
                                <td>FINANZAS Y COMERCIO EXTERIOR</td>
                                <td>FI</td>
                            </tr>
                            <tr>
                                <td>FINANZAS, FINTECH Y COMERCIO EXTERIOR</td>
                                <td>FT</td>
                            </tr>
                            <tr>
                                <td>GESTIÓN DEPORTIVA</td>
                                <td>GD</td>
                            </tr>
                            <tr>
                                <td>HISTORIA</td>
                                <td>HI</td>
                            </tr>
                            <tr>
                                <td>ING. DE SISTEMAS Y TELECOMUNICACIONES</td>
                                <td>SI</td>
                            </tr>
                            <tr>
                                <td>INGENIERÍA AMBIENTAL</td>
                                <td>IA</td>
                            </tr>
                            <tr>
                                <td>INGENIERÍA ELECTRÓNICA</td>
                                <td>IE</td>
                            </tr>
                            <tr>
                                <td>INGENIERÍA INDUSTRIAL</td>
                                <td>IN</td>
                            </tr>
                            <tr>
                                <td>INNOVACIÓN Y TECNOLOGÍA ECONÓMICA</td>
                                <td>IT</td>
                            </tr>
                            <tr>
                                <td>INVESTIGACIÓN CRIMINAL</td>
                                <td>IC</td>
                            </tr>
                            <tr>
                                <td>LICENCIATURA EN FILOSOFÍA Y LETRAS</td>
                                <td>LF</td>
                            </tr>
                            <tr>
                                <td>LOGÍSTICA EMPRESARIAL</td>
                                <td>LO</td>
                            </tr>
                            <tr>
                                <td>MARKETING INTEGRAL Y NEGOCIOS GLOBALES</td>
                                <td>MG</td>
                            </tr>
                            <tr>
                                <td>MARKETING Y NEGOCIOS INTERNACIONALES</td>
                                <td>MK</td>
                            </tr>
                            <tr>
                                <td>MATEMÁTICAS</td>
                                <td>MA</td>
                            </tr>
                            <tr>
                                <td>MÚSICA</td>
                                <td>MU</td>
                            </tr>
                            <tr>
                                <td>NEGOCIOS ESTRATÉGICOS DE MODA</td>
                                <td>NM</td>
                            </tr>
                            <tr>
                                <td>POLÍTICA Y RELACIONES INTERNACIONALES</td>
                                <td>PO</td>
                            </tr>
                            <tr>
                                <td>PREPARATORIO DE MÚSICA</td>
                                <td>PM</td>
                            </tr>
                            <tr>
                                <td>PSICOLOGÍA</td>
                                <td>PS</td>
                            </tr>
                            <tr>
                                <td>PUBLICIDAD INTERNACIONAL</td>
                                <td>PU</td>
                            </tr>
                            <tr>
                                <td>TEATRO MUSICAL</td>
                                <td>TM</td>
                            </tr>
                            <tr>
                                <td>TECNOLOG. EN DIRECCIÓN TÉCNICA DE FÚTBOL</td>
                                <td>TF</td>
                            </tr>
                            <tr>
                                <td>TECNOLOGÍA ADMINISTRACIÓN AGROPECUARIA</td>
                                <td>TG</td>
                            </tr>
                            <tr>
                                <td>TECNOLOGÍA EN CRIMINALÍSTICA</td>
                                <td>TC</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        <table className="schedules_verification_table bq">
                            <thead>
                            <tr className="head">
                                <th colSpan={2}>Barranquilla</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className="head">
                                <th>Programa</th>
                                <th>Prefijo</th>
                            </tr>
                            <tr>
                                <td>ADMINISTRACIÓN DE EMPRESAS BQ</td>
                                <td>ADBQ</td>
                            </tr>
                            <tr>
                                <td>ADMINISTRACIÓN DE NEGOCIOS BQ</td>
                                <td>ANBQ</td>
                            </tr>
                            <tr>
                                <td>COMUNICACIÓN SOCIAL Y PERIODISMO BQ</td>
                                <td>COBQ</td>
                            </tr>
                            <tr>
                                <td>FILOSOFÍA Y HUMANIDADES BQ</td>
                                <td>FB</td>
                            </tr>
                            <tr>
                                <td>DERECHO BQ</td>
                                <td>DEBQ</td>
                            </tr>
                            <tr>
                                <td>MARKETING Y NEGOCIOS INTERNACIONALES BQ</td>
                                <td>MKBQ</td>
                            </tr>
                            <tr>
                                <td>TECNOLOGÍA EN CRIMINALÍSTICA BQ</td>
                                <td>TCBQ</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        <table className="schedules_verification_table sm">
                            <thead>
                            <tr className="head">
                                <th colSpan={2}>Santa Marta</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr className="head">
                                <th>Programa</th>
                                <th>Prefijo</th>
                            </tr>
                            <tr>
                                <td>ADMINISTRACIÓN DE EMPRESAS SM</td>
                                <td>AS</td>
                            </tr>
                            <tr>
                                <td>COMUNICACIÓN SOCIAL Y PERIODISMO SM</td>
                                <td>CS</td>
                            </tr>
                            <tr>
                                <td>CONTADURÍA PÚBLICA SM</td>
                                <td>CP</td>
                            </tr>
                            <tr>
                                <td>DERECHO SM</td>
                                <td>DS</td>
                            </tr>
                            <tr>
                                <td>FINANZAS Y COMERCIO EXTERIOR SM</td>
                                <td>FS</td>
                            </tr>
                            <tr>
                                <td>MARKETING Y NEGOCIOS INTERNACIONALES SM</td>
                                <td>MS</td>
                            </tr>
                            <tr>
                                <td>PSICOLOGÍA SM</td>
                                <td>PSM</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Footer
                    handleNameLinkClick={this.handleNameLinkClick}
                />
            </div>
        );
    }

}

export default SchedulesVerificationGuide;
