import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SURVEY_LINK = "https://forms.gle/KwtPGj1LVDZSyM7M7";

export default class SurveyModal extends React.Component {

	showSurvey = () => {
		this.openSurvey();
		this.handleClose();
	};

	handleClose = () => this.props.setSurveyFormVisible(false);
	openSurvey = () => window.open(SURVEY_LINK, '_blank').focus();

	render() {
		return (
			<Dialog
				open={this.props.openSurveyForm}
				aria-labelledby="form-dialog-title"
				scroll="body"
				onClose={this.handleClose}>

				<DialogTitle id="form-dialog-title">¡Nuestro siguiente objetivo! <span role="img"
																																							 aria-label="trophy">📈🏆</span></DialogTitle>
				<DialogContent>
					<DialogContentText>
						<b>Queremos ayudar a las personas recién graduadas del colegio a encontrar qué carrera deben elegir según
							sus
							gustos, habilidades y objetivos. <span role="img" aria-label="student">🧑‍🎓👨‍🎓</span></b><br/>
					</DialogContentText>
					<DialogContentText>
						<i>Para poder hacerlo, necesitamos de tu colaboración. Por favor realiza la siguiente encuesta:</i>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color="primary">
						Cancelar
					</Button>
					<Button onClick={this.showSurvey} color="primary">
						Realizar encuesta
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}