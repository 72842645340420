import React, {Component} from "react";
import '../styles/SubjectScheduleInformation.css'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import {render} from "react-dom";

const $ = require("jquery");

class SubjectScheduleInformation extends Component {
    cellColor = (hasNormalSchedule) => hasNormalSchedule ? "" : "#F47174";

    displaySubjectSchedule = () => {
        let subject = this.props.subject;
        if (subject && subject.groups) {
            const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
            return <div className="table-responsive filters-schedule">
                <table className="table table-striped table-bordered table-sm" id="sortTable">
                    <thead>
                    <tr className="head">
                        <th scope="col" className="th-sm code">Código</th>
                        <th scope="col" className="th-sm subject">Asignatura</th>
                        <th scope="col" className="th-sm start-hour">Hora inicio</th>
                        <th scope="col" className="th-sm end-hour">Hora final</th>
                        <th scope="col" className="th-sm available-spots">Cupos</th>
                        {
                            days.map((day, i) => {
                                return <th scope="col" key={'day-' + i}>
                                    {day}
                                </th>
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        [...Array(subject.groups.length)].map((element, i) => {
                            return [...Array(subject.groups[i].classes.length)].map((clazz, j) => {
                                return <tr className='subject-schedule-row' key={'tr-' + j}>
                                    <td>
                                        {subject.groups[i].subjectShortCode}
                                    </td>
                                    <td>
                                        <p> {subject.groups[i].name}</p>
                                    </td>
                                    <td>
                                        <p>{subject.groups[i].classes[j].startHourClass !== 0 && subject.groups[i].classes[j].endHourClass !== 0 && subject.groups[i].classes[j].startHourClass + ":00:00"}
                                            {subject.groups[i].classes[j].startHourClass === 0 && subject.groups[i].classes[j].endHourClass === 0 && "N.A."}
                                        </p>
                                    </td>
                                    <td>
                                        <p>{subject.groups[i].classes[j].startHourClass !== 0 && subject.groups[i].classes[j].endHourClass !== 0 && subject.groups[i].classes[j].endHourClass + ":59:59"}
                                            {subject.groups[i].classes[j].startHourClass === 0 && subject.groups[i].classes[j].endHourClass === 0 && "N.A."}
                                        </p>
                                    </td>
                                    <td>
                                        <p> {subject.groups[i].capacity}</p>
                                    </td>
                                    {
                                        [...Array(days.length)].map((day, k) => {
                                            return (
                                                <td style={{backgroundColor: this.cellColor(subject.groups[i].classes[j].startHourClass !== 0 && subject.groups[i].classes[j].endHourClass !== 0)}}>
                                                    <p>{subject.groups[i].classes[j].startHourClass !== 0 && subject.groups[i].classes[j].endHourClass !== 0 && subject.groups[i].classes[j].classDay === k ? "X" : ""}</p>
                                                </td>)
                                        })
                                    }
                                </tr>
                            })
                        })}
                    </tbody>
                </table>
            </div>
        } else return <div/>
    };


    render() {
        return (
            <div>
                {this.displaySubjectSchedule()}
            </div>
        );
    }
}

export default SubjectScheduleInformation;