import React, {Component} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import SubjectScheduleInformation from "./components/SubjectScheduleInformation";
import SearchSubjectForm from "./components/SearchSubjectForm";
import './styles/App.css'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

class SearchSubjectSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {subject: null}
    }

    // UserIdentityForm callback
    handleSubjectShortCodeValue = (subjectValue) => {
        this.showSubjectNotification(subjectValue);

        this.setState({
            subject: subjectValue,
        });
    };

    showSubjectNotification(subjectValue) {
        let subjectExist = subjectValue.name;
        if (subjectExist) {
            if (subjectValue.groups.length > 0) {
                this.showSuccessAlert('Asignatura encontrada', subjectValue.name);
            } else {
                this.showErrorAlert('Error', 'Asignatura no posee información');
            }
        } else {
            this.showErrorAlert('Error', 'Código de asignatura inválido');
        }
    }

    showSuccessAlert(title, message) {
        NotificationManager.success(message, title);
    }

    showErrorAlert(title, message) {
        NotificationManager.error(message, title);
    }

    render() {
        return (<div className="App">
                <NotificationContainer/>

                <Header
                    title="Horarios ofertados por asignatura"
                    periodKey="period"
                />

                <SearchSubjectForm
                    onSubmit={this.handleSubjectShortCodeValue}
                />

                <SubjectScheduleInformation
                    subject={this.state.subject}/>

                <Footer
                    handleNameLinkClick={this.handleNameLinkClick}
                />
            </div>
        );
    }

}

export default SearchSubjectSchedule;
