import React, {Component} from 'react';
import gql from 'graphql-tag'
import {graphql} from 'react-apollo'

class CareerSubjectsForm extends Component {

	onChangeSubject = (event) => {
		let subject = event.target.value;
		let addSubject = event.target.checked;
		this.props.onSubmit(subject, addSubject);
	};

	isSubjectSelected(subjectCode) {
		let subjectsSelected = JSON.parse('[' + this.props.subjectsSelected + ']');
		for (let i = 0; i < subjectsSelected.length; i++)
			if (subjectsSelected[i].code === subjectCode) return true;
		return false;
	};

	displayCareerSubjects() {
		let data = this.props.data;

		if (data && !data.loading && data.career && typeof data.career !== 'undefined' && data.career[0]) {

			if (data.career[0].subjects.length === 0) {
				return <div>
					<h4 className="alert-heading text-center">¡En este semestre no tienes asignaturas por cursar!</h4>
				</div>
			}

			return data.career[0].subjects.map(subject => {
				return <div key={subject.code} className="d-block">
					<label>
						<input type='checkbox' value={JSON.stringify(subject)} itemID={subject.code}
									 checked={this.isSubjectSelected(subject.code)}
									 onChange={this.onChangeSubject}/> {subject.name} - {subject.shor} - 
						<span className='span_credits font-italic'> {subject.credits} CR.</span>
					</label>
				</div>
					;
			});
		}
	}

	render() {
		return (<div className="col-md mb-3">
				<div className="card bg-light">
					<div className="card-header"><h4>Asignaturas a Cursar</h4></div>
					<div className="card-body">
						<p>Seleccione todas las asignaturas que quiere cursar en el semestre seleccionado:</p>
						<form className="elements-container">
							<div className="checkbox">
								{this.displayCareerSubjects()}
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}

}

const GET_CAREER_SUBJECTS = gql`
			query($careerCode: Int!, $semester: Int!, $studentCode: String!) {
  			career (filter: {careerCode: $careerCode, subjectsSemester: $semester, studentCode: $studentCode}){
    			subjects {
      			code
      			name
      			credits
      			english
      			elective
				shor
    			}
  			}
			}
			`;

const FormWithData = graphql(GET_CAREER_SUBJECTS,
	{
		options: ({careerCode, semester, studentCode}) => ({
			variables: {
				careerCode: careerCode, semester: semester, studentCode: studentCode
			}
		})
	})(CareerSubjectsForm);

export default FormWithData;