import {Component} from "react";
import React from "react";
import '../../styles/Cell.css'

const activeColor = 'red';
const inactiveColor = 'white';
const hoverColor = '#1111';

class Cell extends Component {

	constructor(props) {
		super(props);
		this.state = ({
			active: this.props.active,
			hover: false,
			backgroundColor: this.props.active ? activeColor : inactiveColor
		})
	}

	componentWillUpdate(nextProps, nextState, nextContext) {
		if (nextProps.rowClicked === this.props.i) {
			this.props.resetRowClicked();
			if (nextProps.activeCellsSet !== this.state.active) {
				this.onClickCell();
			}

		} else if (nextProps.columnClicked === this.props.j) {
			this.props.resetColumnClicked();
			if (nextProps.activeCellsSet !== this.state.active) {
				this.onClickCell();
			}

		} else if (nextProps.blockCells.length === 0) {
			this.resetCellIfActive();
		}
	}

	resetCellIfActive = () => {
		if (this.state.active) {
			this.setState({active: false, backgroundColor: inactiveColor});
		}
	};

	onClickCell = () => {
		this.changeState();
		this.props.addOrDeleteCell(this.props.i, this.props.j);
	};

	changeState = () =>
		this.setState({active: !this.state.active},
			() => this.setState({backgroundColor: this.state.active ? activeColor : inactiveColor})
		);

	handleHover = () => this.setState({hover: !this.state.hover});

	render() {
		const cellStyle = {
			backgroundColor: (
				this.state.hover && this.state.backgroundColor === inactiveColor ?
					hoverColor : this.state.backgroundColor)
		};

		return <td style={cellStyle} onClick={this.onClickCell}
							 onDragOver={this.handleDrag}
							 onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}
							 key={'td-' + this.props.i + '-' + this.props.j}
							 className='cell-filters-schedule'/>
	}

}

export default Cell;