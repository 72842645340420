import React, {Component} from 'react';
import '../styles/Header.css'
import gql from "graphql-tag";
import {graphql} from "react-apollo";

class Header extends Component {

    render() {
        return (
            <div className="header text-center">
                <div className="logo">
                    <img className="logo__img" src='./images/logo.png' alt="Logo"/>
                </div>
                <div className="title">
                    <h1>{this.props.title}</h1>
                    {!this.props.data.loading && <h3>Período {this.props.data.constant.value}</h3>}
                </div>
            </div>
        );
    }
}

const GET_PERIOD = gql`
			query($key: String!) {
			constant(key: $key) { 
			value
			}
			}
			`;

const HeaderWithData = graphql(GET_PERIOD,
    {
        options: ({periodKey}) => ({
            variables: {
                key: periodKey
            }
        })
    })(Header);

export default HeaderWithData;