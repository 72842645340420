import React, {Component} from 'react';
import Header from "./components/Header";
import UserIdentityForm from "./components/UserIdentityForm";
import UserCareersForm from "./components/UserCareersForm";
import CareerSubjectsForm from "./components/CareerSubjectsForm";
import UserSubjectsForm from "./components/UserSubjectsForm";
import GenerateButton from "./components/GenerateButton";
import Schedule from "./components/Schedule";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import './styles/App.css'
import FiltersScheduleModal from "./components/filters_schedule/FiltersScheduleModal";
import SeeMoreButton from "./components/SeeMoreButton";
import Footer from "./components/Footer";
import RatingForm from "./components/rating/RatingForm";
import ReactGA from 'react-ga';
import SurveyModal from "./components/survey/SurveyModal";

function initGoogleAnalytics() {
    ReactGA.initialize('UA-137846550-1');
    ReactGA.pageview('/home');
}

const DELAY_SURVEY_FORM = 180000;

class GenerateSchedules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            student: null,
            studentCareerCode: 0,
            studentSemester: 0,
            careerSeat: 'NaN',
            studentSubjects: [],
            subjectsCode: [],
            totalCredits: 0,
            generateButtonClicked: false,
            schedules: [],
            loading: false,
            showFiltersScheduleModal: false,
            blockCells: [],
            hasMoreSchedules: false,
            openRatingForm: false,
            openSurveyForm: false
        };

        this.infoSectionsRef = React.createRef();
        initGoogleAnalytics();
    }

    // UserIdentityForm callback
    handleStudentIdentityValue = (studentValue, studentIdentity) => {
        this.showUserNotification(studentValue, studentIdentity);
        this.setState({
            student: studentValue,
            studentCareerCode: 0,
            studentSemester: 0,
            careerSeat: 'NaN',
            studentSubjects: [],
            subjectsCode: [],
            totalCredits: 0,
            generateButtonClicked: false,
            schedules: [],
            blockCells: [],
            hasMoreSchedules: false,
            openRatingForm: false,
            openSurveyForm: false
        });
    };

    showUserNotification(studentValue, studentIdentity) {
        let studentExist = studentValue.careers;
        if (studentExist) {
            let studentName = studentValue.name.charAt(0).toUpperCase() + studentValue.name.toLowerCase().slice(1);
            let studentLastName = studentValue.lastName.charAt(0).toUpperCase() + studentValue.lastName.toLowerCase().slice(1);
            this.showSuccessAlert('Bienvenido ' + studentName + ' ' + studentLastName, 'Ingresa tu información académica');
            this.infoSectionsRef.current.scrollIntoView({behavior: 'smooth'});

            // successful login - event registering
            ReactGA.event({
                category: 'UserIdentityForm',
                action: 'Login',
                label: 'Successful',
            });

            // successful login - event registering
            ReactGA.event({
                category: 'UserIdentityForm',
                action: 'Login',
                label: 'Successful - ' + studentIdentity
            });

            // careers number - event registering
            let careerNames = [];
            studentValue.careers.forEach((career) => careerNames.push(career.name));
            careerNames.sort();

            ReactGA.event({
                category: 'AcademicData',
                action: 'Careers',
                label: careerNames.toString(),
                value: studentValue.careers.length
            });

        } else {
            this.showErrorAlert('Error', 'Documento de identidad inválido');
            // unsuccessful login - event registering
            ReactGA.event({
                category: 'UserIdentityForm',
                action: 'Login',
                label: 'Unsuccessful - ' + studentIdentity
            });
        }
    }

    showSuccessAlert(title, message) {
        NotificationManager.success(message, title);
    }

    showErrorAlert(title, message) {
        NotificationManager.error(message, title);
    }

    // Survey Form delay & callback
    runSurveyFormCron = () => {
        setTimeout(() => {
            this.setState({
                openSurveyForm: true
            });
            // rating modal - event registering
            ReactGA.event({
                category: 'Survey Modal',
                action: 'Show'
            });
        }, DELAY_SURVEY_FORM);
    };

    // UserCareersForm callback
    handleStudentCareerValue = (studentCareer, studentCareerCodeValue, studentSemesterValue, careerSeatValue) => {
        this.setState({
            studentCareerCode: studentCareerCodeValue,
            studentSemester: studentSemesterValue,
            careerSeat: careerSeatValue
        });

        // user career - event registering
        ReactGA.event({
            category: 'UserCareersForm',
            action: 'Career Selected',
            label: studentCareer
        });

        // user semester - event registering
        ReactGA.event({
            category: 'UserCareersForm',
            action: 'Semester Selected',
            label: studentSemesterValue.toString()
        });

        // user career/semester - event registering
        ReactGA.event({
            category: 'UserCareersForm',
            action: 'Career/Semester Selected',
            label: studentCareer + ", " + studentSemesterValue.toString()
        });
    };

    // CareerSubjectsFrom/UserSubjectsForm callback
    handleStudentSubjects = (subjectSelected, addSubject) => {

        let subject = JSON.parse(subjectSelected);
        if (addSubject) {
            this.state.studentSubjects.push(subjectSelected);
            this.state.subjectsCode.push(subject.code);

            // subject selected - event registering
            ReactGA.event({
                category: 'UserSubjectsForm',
                action: 'Subject Selected',
                label: JSON.parse(subjectSelected).name
            });
        } else {
            let subjectIndex = this.state.studentSubjects.indexOf(subjectSelected);
            this.state.studentSubjects.splice(subjectIndex, 1);
            let subjectCodeIndex = this.state.subjectsCode.indexOf(subject.code);
            this.state.subjectsCode.splice(subjectCodeIndex, 1);

            // subject unselected - event registering
            ReactGA.event({
                category: 'UserSubjectsForm',
                action: 'Subject Unselected',
                label: JSON.parse(subjectSelected).name
            });
        }

        this.setState({
            studentSubjects: this.state.studentSubjects,
            subjectsCode: this.state.subjectsCode,
            totalCredits: addSubject ? this.state.totalCredits + subject.credits : this.state.totalCredits - subject.credits
        });
    };

    // GenerateButton callback
    handleSchedulesGenerated = (schedules) => {
        this.setState({
            schedules,
            generateButtonClicked: true,
            loading: false
        });

        // generate button clicked - event registering
        ReactGA.event({
            category: 'FiltersScheduleModal',
            action: 'Generate Schedules Clicked'
        });

        // total credits - event registering
        ReactGA.event({
            category: 'AcademicData',
            action: 'Credits',
            label: this.state.totalCredits.toString()
        });

    };

    // Use for rendering loading spinner
    setLoadingState = () => this.setState({loading: true});

    // GenerateButton/FiltersScheduleModal callback
    showModal = (show) => {
        this.setState({showFiltersScheduleModal: show});
        // generate button clicked - event registering
        if (show) {
            ReactGA.event({
                category: 'GenerateButton',
                action: 'Click'
            });
        }
    };

    // FiltersScheduleModal functions
    addCell = (i, j) => {
        this.state.blockCells.push({i: i, j: j});
        // cell selected - event registering
        ReactGA.event({
            category: 'FiltersScheduleModal',
            action: 'Cell selected',
            label: "i: " + i + ", j:" + j
        });
    };

    deleteCell = (cellIndex, i, j) => {
        this.state.blockCells.splice(cellIndex, 1);
        // cell unselected - event registering
        ReactGA.event({
            category: 'FiltersScheduleModal',
            action: 'Cell unselected',
            label: "i: " + i + ", j:" + j
        });
    };

    clearCells = () => {
        this.setState({blockCells: []});
        // clear cells - event registering
        ReactGA.event({
            category: 'FiltersScheduleModal',
            action: 'Clear cells Clicked'
        });
    };

    updatePreferredTime = (preferredTime) => {
        this.setState({preferredTime});
        // preferred time - event registering
        ReactGA.event({
            category: 'FiltersScheduleModal',
            action: 'Preferred Time',
            label: preferredTime
        });
    };

    // SeeMoreButton callback
    setHasMoreSchedules = (hasMoreSchedules) => {
        this.setState({hasMoreSchedules});
        // see more button - event registering
        ReactGA.event({
            category: 'Schedules',
            action: 'SeeMoreButton Clicked'
        });
    };

    setMoreSchedules = (schedules) => this.setState({
        schedules: this.state.schedules.concat(schedules),
        loading: false
    });

    // Show RatingForm
    showRatingForm = () => {
        this.setState({
            openRatingForm: true
        });
        // rating modal - event registering
        ReactGA.event({
            category: 'Rating Modal',
            action: 'Show'
        });
    };

    setRatingFormVisible = (openRatingForm) => this.setState({openRatingForm});

    setSurveyFormVisible = (openSurveyForm) => this.setState({openSurveyForm});

    createRating = (rating, comments) => {
        ReactGA.event({
            category: 'Rating Modal',
            action: 'Rating ' + rating,
            label: comments,
            value: rating
        });
    };

    // Schedule callback
    scheduleDownloaded = (scheduleID) => {
        // schedule downloaded - event registering
        ReactGA.event({
            category: 'Schedules',
            action: 'Download',
            label: scheduleID
        });

        //this.showRatingForm();
    };

    noSchedules = () => {
        // schedule downloaded - event registering
        let subjectNames = [];
        this.state.studentSubjects.forEach((subject) => subjectNames.push(JSON.parse(subject).name));
        subjectNames.sort();

        ReactGA.event({
            category: 'Schedules',
            action: 'None',
            label: subjectNames.toString()
        });
    };

    // Footer callback
    handleNameLinkClick = () => {
        ReactGA.event({
            category: 'Footer',
            action: 'My Name Clicked'
        });
    };

    render() {
        return (<div className="App">
                <NotificationContainer/>
                {this.state.loading && <div id="cover-spin"><h3 id="text-spin">Cargando horarios...</h3></div>}

                <Header
                    title="Simulador Generador de Horarios"
                    periodKey="period"
                />

                <UserIdentityForm
                    onSubmit={this.handleStudentIdentityValue}
                />

                <div id="info_sections" ref={this.infoSectionsRef}>
                    <div className="row m-2">

                        <UserCareersForm
                            student={this.state.student}
                            onSubmit={this.handleStudentCareerValue}
                            errorAlert={this.showErrorAlert}
                        />

                        <CareerSubjectsForm
                            studentCode={this.state.student ? this.state.student.code : null}
                            careerCode={this.state.studentCareerCode}
                            semester={this.state.studentSemester}
                            subjectsSelected={this.state.studentSubjects}
                            onSubmit={this.handleStudentSubjects}
                        />

                        <UserSubjectsForm
                            subjectsSelected={this.state.studentSubjects}
                            totalCredits={this.state.totalCredits}
                            onSubmit={this.handleStudentSubjects}
                        />

                    </div>

                    <GenerateButton
                        careerSeat={this.state.careerSeat}
                        subjectsCode={this.state.subjectsCode}
                        errorAlert={this.showErrorAlert}
                        showFiltersScheduleModal={this.showModal}
                    />

                </div>

                <FiltersScheduleModal
                    careerSeat={this.state.careerSeat}
                    subjectsCode={this.state.subjectsCode}
                    showValue={this.state.showFiltersScheduleModal}
                    showModal={this.showModal}
                    setSchedules={this.handleSchedulesGenerated}
                    loading={this.setLoadingState}
                    blockCells={this.state.blockCells}
                    addCell={this.addCell}
                    deleteCell={this.deleteCell}
                    clearCells={this.clearCells}
                    updatePreferredTime={this.updatePreferredTime}
                    setHasMoreSchedules={this.setHasMoreSchedules}
                />

                {
                    this.state.generateButtonClicked
                    &&
                    <Schedule
                        schedules={this.state.schedules}
                        scheduleDownloaded={this.scheduleDownloaded}
                        noSchedules={this.noSchedules}
                    />
                }

                {
                    this.state.hasMoreSchedules
                    &&
                    <SeeMoreButton
                        careerSeat={this.state.careerSeat}
                        subjectsCode={this.state.subjectsCode}
                        blockCells={this.state.blockCells}
                        preferredTime={this.state.preferredTime}
                        loading={this.setLoadingState}
                        setMoreSchedules={this.setMoreSchedules}
                        setHasMoreSchedules={this.setHasMoreSchedules}
                    />
                }

                <RatingForm
                    openRatingForm={this.state.openRatingForm}
                    setRatingFormVisible={this.setRatingFormVisible}
                    showSuccessAlert={this.showSuccessAlert}
                    showErrorAlert={this.showErrorAlert}
                    createRating={this.createRating}
                />

                <SurveyModal
                    openSurveyForm={this.state.openSurveyForm}
                    setSurveyFormVisible={this.setSurveyFormVisible}
                />

                <Footer
                    handleNameLinkClick={this.handleNameLinkClick}
                />
            </div>
        );
    }

}

export default GenerateSchedules;
