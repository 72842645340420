import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from 'react-apollo';

import App from './App';

import * as serviceWorker from './serviceWorker';

import {ApolloClient, ApolloLink, InMemoryCache, HttpLink} from 'apollo-boost';

const httpLink = new HttpLink({uri: 'https://horariosalumnos.usergioarboleda.edu.co/graphql'});
//const httpLink = new HttpLink({uri: 'http://localhost:8080/graphql'});

const authLink = new ApolloLink((operation, forward) => {
    const token = '1023039493';
    operation.setContext({
        headers: {
            authorization: `Bearer ${token}`
        },
    });
    return forward(operation);
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});


const Root = () => (
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>
);

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();
