import {Component} from "react";
import React from "react";
import Cell from "./Cell";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import '../../styles/FiltersScheduleModal.css'
import gql from 'graphql-tag';
import {ApolloConsumer} from "react-apollo";
import {GraphQLEnumType} from "graphql";

class FiltersScheduleModal extends Component {

	constructor(props) {
		super(props);
		this.state = ({preferredTime: 'ANY', columnClicked: -1, rowClicked: -1, activeCellsSet: false});
	}

	cellIndexOf(i, j) {
		for (let index = 0, cellIndexes; index < this.props.blockCells.length; index++) {
			cellIndexes = this.props.blockCells[index];
			if (i === cellIndexes.i && j === cellIndexes.j) {
				return index;
			}
		}
		return -1;
	}

	addOrDeleteCell = (i, j) => {
		let cellIndex = this.cellIndexOf(i, j);
		if (cellIndex === -1) {
			this.props.addCell(i, j);
		} else {
			this.props.deleteCell(cellIndex, i, j);
		}
	};

	onTimeChange = (event) => {
		this.setState({preferredTime: event.target.value});
	};

	displayScheduleStructures = () => {
		return <div className="structure-selector">
			<input id="morning" type="radio" name="structure-option" value="MORNING"
						 checked={this.state.preferredTime === "MORNING"} onChange={this.onTimeChange}/>
			<label className="schedule-structure morning" htmlFor="morning"/>
			<input id="afternoon" type="radio" name="structure-option" value="AFTERNOON"
						 checked={this.state.preferredTime === "AFTERNOON"} onChange={this.onTimeChange}/>
			<label className="schedule-structure afternoon" htmlFor="afternoon"/>
			<input id="night" type="radio" name="structure-option" value="NIGHT"
						 checked={this.state.preferredTime === "NIGHT"} onChange={this.onTimeChange}/>
			<label className="schedule-structure night" htmlFor="night"/>
			<input id="any" type="radio" name="structure-option" value="ANY" checked={this.state.preferredTime === "ANY"}
						 onChange={this.onTimeChange}/>
			<label className="schedule-structure any" htmlFor="any"/>
		</div>
	};

	onRowClick = (event) => {
		let rowClicked = Number(event.target.value);
		let activeRow = false;

		const totalColumns = 6;
		for (let j = 0; j < totalColumns; j++) {
			if (this.cellIndexOf(rowClicked, j) === -1) {
				activeRow = true;
				break;
			}
		}

		this.setState({rowClicked, activeCellsSet: activeRow});
	};

	resetRowClicked = () => this.setState({rowClicked: -1});

	onColumnClick = (event) => {
		let columnClicked = Number(event.target.value);
		let activeColumn = false;

		const totalRows = 17;
		for (let i = 0; i < totalRows; i++) {
			if (this.cellIndexOf(i, columnClicked) === -1) {
				activeColumn = true;
				break;
			}
		}

		this.setState({columnClicked, activeCellsSet: activeColumn});
	};

	resetColumnClicked = () => this.setState({columnClicked: -1});

	displayFiltersSchedule = () => {
		const numRows = 17;
		const numColumns = 6;
		const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
		return <div className="table-responsive filters-schedule">
			<table className="table table-bordered">
				<thead>
				<tr className='filters-schedule-row'>
					<th scope="col" className="filters-title">Hora</th>
					{
						days.map((day, i) => {
							return <th scope="col" key={'day-' + i}>
								<button className="filters-title day-row-column-button" value={i} onClick={this.onColumnClick}>
									{day}
								</button>
							</th>
						})
					}
				</tr>
				</thead>
				<tbody>
				{
					[...Array(numRows)].map((element, i) => {
						return <tr className='filters-schedule-row' key={'tr-' + i}>
							<th scope="row" key={'th-' + i}>
								<button className="filters-title day-row-column-button" value={i} onClick={this.onRowClick}>
									{i + 6>12 && (i + 6-12) +' PM'}{i + 6===12 && (i + 6) +' PM'}{i + 6<12 && (i + 6) +' AM' }
								</button>
							</th>
							{
								[...Array(numColumns)].map((element, j) => {
									return <Cell
										key={'cell-' + i + '-' + j}
										i={i}
										j={j}
										blockCells={this.props.blockCells}
										active={this.cellIndexOf(i, j) !== -1}
										addOrDeleteCell={this.addOrDeleteCell}
										columnClicked={this.state.columnClicked}
										resetColumnClicked={this.resetColumnClicked}
										rowClicked={this.state.rowClicked}
										resetRowClicked={this.resetRowClicked}
										activeCellsSet={this.state.activeCellsSet}
									/>
								})
							}
						</tr>
					})
				}
				</tbody>
			</table>
		</div>
	};


	onClearButtonClick = () => {
		this.props.clearCells();
		this.setState({preferredTime: 'ANY', columnClicked: -1, columnsActive: [], rowsActive: []});
	};

	onAcceptButtonClick = (client) => {
		this.loadSchedules(client)
			.then((data) => {
				if (data.schedule!=null){
					this.props.setHasMoreSchedules(data.schedule.next);
					this.onSchedulesFetched(data.schedule.schedules);
				}else{
					this.props.setHasMoreSchedules("vacío");
					this.onSchedulesFetched(null);
				}
				this.props.showModal(false);
			})
			.catch((reason) => {
				console.log('Error fetching schedules. %s', reason);
				this.props.showModal(false);
			});
	};

	async loadSchedules(client) {
		this.props.loading();
		this.props.updatePreferredTime(this.state.preferredTime);
		const {data} = await client.query({
			query: GET_SCHEDULES,
			variables: {
				careerSeat: this.props.careerSeat,
				subjectsCode: this.props.subjectsCode.sort(),
				blockCells: this.props.blockCells,
				preferredTime: new GraphQLEnumType({name: this.state.preferredTime, values: {FOO: {}}})
			}
		});
		return data;
	}

	onSchedulesFetched = schedules => this.props.setSchedules(schedules);

	render() {
		return <ApolloConsumer>
			{client =>
				<Modal size="lg" show={this.props.showValue} onHide={() => this.props.showModal(false)}>

					<Modal.Header closeButton>
						<Modal.Title>Preferencia Horaria</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p>¿A qué hora prefieres las clases?</p>
						{this.displayScheduleStructures()}
						<p className='d-inline'>Selecciona los bloques donde</p> <p className="d-inline font-weight-bold">NO</p>
						<p className='d-inline'> quieres tener clase:</p>
						{this.displayFiltersSchedule()}
					</Modal.Body>

					<Modal.Footer>
						<Button variant="secondary" onClick={this.onClearButtonClick}>Limpiar</Button>
						<Button variant="contained" className="general_button" onClick={() => this.onAcceptButtonClick(client)}>Aceptar</Button>
					</Modal.Footer>
				</Modal>
			}
		</ApolloConsumer>
	}

}

const GET_SCHEDULES = gql`
			query($careerSeat: String!, $subjectsCode: [Int!]!, $blockCells: [Cell!], $preferredTime: DayTime!) {
  			schedule(payload: {careerSeat: $careerSeat, subjectsCode: $subjectsCode, blockCells: $blockCells, preferredTime: $preferredTime}, offset: 0) {
  				schedules {
  					code
    				listRepresentation
    		 	}
    		 	next
  			} 
			}	
			`;

export default FiltersScheduleModal;