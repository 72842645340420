import React, {Component} from 'react';

class GenerateButton extends Component {

	showFiltersScheduleModal = () => {
		if (this.validData()) {
			this.props.showFiltersScheduleModal(true);
		} else {
			this.props.errorAlert('Error', 'Debes seleccionar al menos una asignatura para realizar esta acción.');
		}
	};

	validData() {
		return this.props.careerSeat !== 'NaN' && this.props.subjectsCode.length > 0;
	}

	render() {
		return (
			<div className="col text-center mb-3">
				<button type="button" className="btn general_button" onClick={this.showFiltersScheduleModal}>
					Generar
				</button>
			</div>
		)
	}

}

export default GenerateButton;