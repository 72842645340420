import React from "react";
import {NavLink} from "react-router-dom";

export default ({close}) => (
    <div className="menu">
        <ul>
            <li>
                <NavLink onClick={close} to="/">
                    Simulador Generador de Horarios
                </NavLink>
            </li>
            <li>
                <NavLink onClick={close} to="horario-por-asignatura">
                    Horarios ofertados por asignatura
                </NavLink>
            </li>
            <li>
                <NavLink onClick={close} to="guia-verificacion-horarios">
                    Guía de verificación de horarios
                </NavLink>
            </li>
        </ul>
    </div>
);
