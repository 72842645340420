import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import RatingReaction from "./RatingReaction";
import StarRatings from 'react-star-ratings';


export default class RatingForm extends React.Component {

	state = {rating: 0, comments: ''};

	handleComments = (event) => {
		this.setState({comments: event.target.value});
	};

	handleClose = () => {
		if (this.state.rating > 0) {
			this.setState({rating: 0});
			this.props.setRatingFormVisible(false);
			this.props.showSuccessAlert('¡Gracias!', 'Seguiremos mejorando para ti');
			this.props.createRating(this.state.rating, this.state.comments);
		} else {
			this.props.showErrorAlert('Error', 'Por favor selecciona una calificación')
		}
	};
	changeRating = (rating) => this.setState({rating});

	render() {
		return (
			<Dialog
				open={this.props.openRatingForm}
				aria-labelledby="form-dialog-title"
				scroll="body">

				<DialogTitle id="form-dialog-title">¡Cuéntanos tu experiencia!
					<RatingReaction
						rating={this.state.rating}
					/></DialogTitle>
				<DialogContent>
					<DialogContentText>
						Sabemos que te está gustando esta increíble herramienta. Por favor califícanos:
					</DialogContentText>

					<div id="star_ratings_container">
						<StarRatings
							rating={this.state.rating}
							numberOfStars={3}
							starDimension="40px"
							starRatedColor="yellow"
							starHoverColor="rgba(255, 255, 0, .5)"
							changeRating={this.changeRating}
							name="stars"
						/>
					</div>

					<TextField
						label="Escribe tus comentarios aquí"
						id="opinion_text_field"
						type="text"
						fullWidth
						multiline={true}
						variant="outlined"
						inputProps={{maxLength: 250}}
						onChange={this.handleComments}
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color="primary">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}