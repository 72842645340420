import React, {Component} from 'react';
import gql from "graphql-tag";
import {ApolloConsumer} from "react-apollo";

class SearchSubjectForm extends Component {

    constructor(props) {
        super(props);
        this.state = {subjectShortCode: '', subjectName: ''};
    }

    onChangeSubjectShortCodeValue = event => this.setState({subjectShortCode: event.target.value});

    handleSubmit = (event, client) => {
        event.preventDefault();
        this.loadSubject(client);
    };

    async loadSubject(client) {
        const {data} = await client.query({
            query: GET_SUBJECT_DATA,
            variables: {
                subjectShortCode: this.state.subjectShortCode
            }
        });
        this.onSubjectFetched(data.subject);
    };

    onSubjectFetched = subject => {
        let subjectName = subject.name ? subject.name : "";
        this.setState({subjectName: subjectName});

        this.props.onSubmit(subject);
    }

    render() {
        return (<ApolloConsumer>
            {client =>
                <div className="container">
                    <div className="row">

                        <div className="col">

                            <div className="card bg-light mb-3">
                                <div className="card-header"><h4>Búsqueda por asignatura</h4></div>
                                <div className="card-body">

                                    <form className="mt-4" onSubmit={(event) => this.handleSubmit(event, client)}>

                                        <div className="input-group">
                                            <label className="control-label col-md-3 mt-2 req" align="start">Ingrese
                                                el
                                                código de la asignatura:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" align="center" type="text"
                                                       maxLength="20" name="codigo_asignatura"
                                                       id="subjectShortCode"
                                                       onChange={this.onChangeSubjectShortCodeValue} required/>
                                            </div>
                                        </div>

                                        <div className="input-group">
                                            <label className="control-label col-md-3 mt-2 req"
                                                   align="start">Nombre:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" align="center" type="text"
                                                       disabled={true}
                                                       value={this.state.subjectName}/>
                                            </div>
                                        </div>

                                        <div className="col text-center mt-3">
                                            <button className="btn btn-light" type="button" id="id_submit"
                                                    onClick={() => this.loadSubject(client)}> Aceptar
                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }
        </ApolloConsumer>);
    }
}

const
    GET_SUBJECT_DATA = gql`
			query($subjectShortCode: String!) {
				subject(shortCode:$subjectShortCode) {
				  code
				  name
	              shor
	              groups {
	                name
	                number
	                capacity
	                separator
	                subjectShortCode
	                classes {
	                  startHourClass
	                  endHourClass
	                  classDay
	                }
	              }
	            }
			}
			`;

export default SearchSubjectForm;