import React, {Component} from 'react';
import gql from "graphql-tag";
import {ApolloConsumer} from "react-apollo";

class UserIdentityForm extends Component {

    constructor(props) {
        super(props);
        this.state = {studentIdentity: '', studentName: ''};
    }

    onChangeStudentIdentityValue = event => this.setState({studentIdentity: event.target.value});

    handleSubmit = (event, client) => {
        event.preventDefault();
        this.loadStudent(client);
    };

    async loadStudent(client) {
        const {data} = await client.query({
            query: GET_STUDENT_DATA,
            variables: {
                studentCode: this.state.studentIdentity
            }
        });
        this.onStudentFetched(data.student);
    };

    onStudentFetched = student => {
        let studentName = '';
        let studentLastName = '';

        if (student.name != null && student.lastName != null) {
            studentName = student.name.charAt(0).toUpperCase() + student.name.toLowerCase().slice(1);
            studentLastName = student.lastName.charAt(0).toUpperCase() + student.lastName.toLowerCase().slice(1);
        }

        this.setState({studentName: studentName + ' ' + studentLastName});

        this.props.onSubmit(student, this.state.studentIdentity);
    }

    render() {
        return (<ApolloConsumer>
            {client =>
                <div className="container">
                    <div className="row">

                        <div className="col">

                            <div className="card bg-light mb-3">
                                <div className="card-header"><h4>Ingrese sus Datos</h4></div>
                                <div className="card-body">

                                    <form className="mt-4" onSubmit={(event) => this.handleSubmit(event, client)}>

                                        <div className="input-group">
                                            <label className="control-label col-md-3 mt-2 req" align="start">Documento
                                                de Identidad:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" align="center" type="text"
                                                       maxLength="20" name="identidad"
                                                       id="identity"
                                                       onChange={this.onChangeStudentIdentityValue} required/>
                                            </div>
                                        </div>

                                        <div className="input-group">
                                            <label className="control-label col-md-3 mt-2 req"
                                                   align="start">Nombre:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" align="center" type="text"
                                                       disabled={true}
                                                       value={this.state.studentName}/>
                                            </div>
                                        </div>

                                        <div className="col text-center mt-3">
                                            <button className="btn btn-light" type="button" id="id_submit"
                                                    onClick={() => this.loadStudent(client)}> Aceptar
                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }
        </ApolloConsumer>);
    }
}

const
    GET_STUDENT_DATA = gql`
			query($studentCode: String!) {
				student(code:$studentCode) {
				  code
				  name
				  lastName
					careers {
						code
						name
						seat
						shortCode
					}
				}
			}
			`;

export default UserIdentityForm;